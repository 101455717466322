define(['require', 'exports',
    'libs/GanttChart/ganttUtilities',
    'libs/GanttChart/ganttTask',
    'libs/GanttChart/ganttDrawerSVG',
    'libs/GanttChart/ganttGridEditor',
    'libs/GanttChart/ganttMaster'
], function (require, exports, ganttUtilities, ganttTask, ganttDrawerSVG, ganttGridEditor, ganttMaster) {
    exports.GanttMaster = ganttMaster.GanttMaster;
    exports.GridEditor = ganttGridEditor.GridEditor;
    exports.Ganttalendar = ganttDrawerSVG.Ganttalendar;

    exports.Task = ganttTask.Task;
    exports.TaskFactory = ganttTask.TaskFactory;
});