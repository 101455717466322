Object.size = function (obj) {
    var size = 0, key;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }
    return size;
};

Storage.prototype.setObject = function (key, value) {
    this.setItem(key, JSON.stringify(value));
};

Storage.prototype.getObject = function (key) {
    return this.getItem(key) && JSON.parse(this.getItem(key));
};

jQuery.fn.activateLinks = function (showImages) {
    var httpRE = /(['"]\s*)?(http[s]?:[\d]*\/\/[^"<>\s]*)/g;
    var wwwRE = /(['"/]\s*)?(www\.[^"<>\s]+)/g;
    var imgRE = /(['"]\s*)?(http[s]?:[\d]*\/\/[^"<>\s]*\.(?:gif|jpg|png|jpeg|bmp))/g;


    this.each(function () {
        var el = $(this);
        var html = el.html();

        if (showImages) {
            // workaround for negative look ahead
            html = html.replace(imgRE, function ($0, $1) {
                return $1 ? $0 : "<div class='imgWrap'  onclick=\"window.open('" + $0 + "','_blank');event.stopPropagation();\"><img src='" + $0 + "' title='" + $0 + "'></div>";
            });
        }

        html = html.replace(httpRE, function ($0, $1) {
            return $1 ? $0 : "<a href='#' onclick=\"window.open('" + $0 + "','_blank');event.stopPropagation();\">" + $0 + "</a>";
        });

        html = html.replace(wwwRE, function ($0, $1) {
            return $1 ? $0 : "<a href='#' onclick=\"window.open('http://" + $0 + "','_blank');event.stopPropagation();\">" + $0 + "</a>";
        });

        el.empty().append(html);

        if (showImages) {
            //inject expand capability on images
            el.find("div.imgWrap").each(function () {
                var imageDiv = $(this);


                imageDiv.click(function (e) {
                    if (e.ctrlKey || e.metaKey) {
                        window.open(imageDiv.find("img").prop("src"), "_blank");
                    } else {
                        var imageClone = imageDiv.find("img").clone();
                        imageClone.mouseout(function () {
                            $(this).remove();
                        });
                        imageClone.addClass("imageClone").css({
                            "position": "absolute",
                            "display": "none",
                            "top": imageDiv.position().top,
                            "left": imageDiv.position().left,
                            "z-index": 1000000
                        });
                        imageDiv.after(imageClone);
                        imageClone.fadeIn();
                    }
                });
            });
        }

    });
    return this;
};

jQuery.fn.emoticonize = function () {
    function convert(text) {
        var faccRE = /(:\))|(:-\))|(:-])|(:-\()|(:\()|(:-\/)|(:-\\)|(:-\|)|(;-\))|(:-D)|(:-P)|(:-p)|(:-0)|(:-o)|(:-O)|(:'-\()|(\(@\))/g;
        return text.replace(faccRE, function (str) {
            var ret = {
                ":)": "smile",
                ":-)": "smile",
                ":-]": "polite_smile",
                ":-(": "frown",
                ":(": "frown",
                ":-/": "skepticism",
                ":-\\": "skepticism",
                ":-|": "sarcasm",
                ";-)": "wink",
                ":-D": "grin",
                ":-P": "tongue",
                ":-p": "tongue",
                ":-o": "surprise",
                ":-O": "surprise",
                ":-0": "surprise",
                ":'-(": "tear",
                "(@)": "angry"
            }[str];
            if (ret) {
                ret = "<img src='" + contextPath + "/img/smiley/" + ret + ".png' align='absmiddle'>";
                return ret;
            } else
                return str;
        });
    }

    function addBold(text) {
        var returnedValue;
        var faccRE = /\*\*[^*]*\*\*/ig;
        return text.replace(faccRE, function (str) {
            var temp = str.substr(2);
            var temp2 = temp.substr(0, temp.length - 2);
            return "<b>" + temp2 + "</b>";
        });
    }

    this.each(function () {
        var el = $(this);
        var html = convert(el.html());
        html = addBold(html);
        el.html(html);
    });
    return this;
};


$.fn.unselectable = function () {
    this.each(function () {
        $(this).addClass("unselectable").attr("unselectable", "on");
    });
    return $(this);
};

$.fn.clearUnselectable = function () {
    this.each(function () {
        $(this).removeClass("unselectable").removeAttr("unselectable");
    });
    return $(this);
};

$.fn.isEmptyElement = function( ){
  return !$.trim($(this).html())
};

if (typeof ($.fn.size) != "funcion")
    $.fn.size = function () {
        return this.length
    };