
/* Custom function*/

import * as $ from "jquery";

export function nearBestPosition(target, element) {
        var $target = $(target);
        var $element = $(element);

        var targetOffset = $target.offset();
        var targetWidth = $target.outerWidth();
        var targetHeight = $target.outerHeight();
        
        var elementWidth = $element.outerWidth();
        var elementHeight = $element.outerHeight();
        
        var viewportWidth = $(window).width();
        var viewportHeight = $(window).height();

        var positions = [
            { left: targetOffset.left + targetWidth, top: targetOffset.top }, // right
            { left: targetOffset.left - elementWidth, top: targetOffset.top }, // left
            { left: targetOffset.left, top: targetOffset.top + targetHeight }, // below
            { left: targetOffset.left, top: targetOffset.top - elementHeight } // above
        ];

        // Filter out positions that are outside the viewport
        var validPositions = positions.filter(function(pos) {
            return pos.left >= 0 && pos.top >= 0 && 
                   pos.left + elementWidth <= viewportWidth && 
                   pos.top + elementHeight <= viewportHeight;
        });

        if (validPositions.length > 0) {
            var bestPosition = validPositions[0]; // Default to the first valid position

            // Optionally, you could add more logic here to determine the best position, 
            // such as prioritizing certain positions over others.

            $element.css(bestPosition);
        } else {
            // If no valid positions, fallback to the default position
            $element.css({ left: targetOffset.left, top: targetOffset.top });
        }

        return this;
    };
