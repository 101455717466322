define(['exports'], function (exports) {
    var messages = {
        "CANNOT_WRITE": "CANNOT_WRITE",
        "CHANGE_OUT_OF_SCOPE": "NO_RIGHTS_FOR_UPDATE_PARENTS_OUT_OF_EDITOR_SCOPE",
        "START_IS_MILESTONE": "START_IS_MILESTONE",
        "END_IS_MILESTONE": "END_IS_MILESTONE",
        "TASK_HAS_CONSTRAINTS": "TASK_HAS_CONSTRAINTS",
        "GANTT_ERROR_DEPENDS_ON_OPEN_TASK": "GANTT_ERROR_DEPENDS_ON_OPEN_TASK",
        "GANTT_ERROR_DESCENDANT_OF_CLOSED_TASK": "GANTT_ERROR_DESCENDANT_OF_CLOSED_TASK",
        "TASK_HAS_EXTERNAL_DEPS": "TASK_HAS_EXTERNAL_DEPS",
        "GANTT_ERROR_LOADING_DATA_TASK_REMOVED": "GANTT_ERROR_LOADING_DATA_TASK_REMOVED",
        "CIRCULAR_REFERENCE": "CIRCULAR_REFERENCE",
        "CANNOT_MOVE_TASK": "CANNOT_MOVE_TASK",
        "CANNOT_DEPENDS_ON_ANCESTORS": "CANNOT_DEPENDS_ON_ANCESTORS",
        "CANNOT_DEPENDS_ON_DESCENDANTS": "CANNOT_DEPENDS_ON_DESCENDANTS",
        "INVALID_DATE_FORMAT": "INVALID_DATE_FORMAT",
        "GANTT_SEMESTER_SHORT": "GANTT_SEMESTER_SHORT",
        "GANTT_SEMESTER": "GANTT_SEMESTER",
        "GANTT_QUARTER_SHORT": "GANTT_QUARTER_SHORT",
        "GANTT_QUARTER": "GANTT_QUARTER",
        "GANTT_WEEK": "GANTT_WEEK",
        "GANTT_WEEK_SHORT": "GANTT_WEEK_SHORT",
        "CANNOT_CLOSE_TASK_IF_OPEN_ISSUE": "CANNOT_CLOSE_TASK_IF_OPEN_ISSUE",
        "PLEASE_SAVE_PROJECT": "PLEASE_SAVE_PROJECT",
        "CANNOT_CREATE_SAME_LINK": "CANNOT_CREATE_SAME_LINK"
    };

    exports.GanttMessages = messages;
});